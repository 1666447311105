<template>
  <div>
    <button
      v-b-modal.multiple-currency-modal
      class="navbar-button px-2 mr-1"
      data-cy="currency-selector-btn"
    >
      {{ SUPPORT_MCP_CURRENCIES[selectedCurrency].symbol }}
    </button>

    <b-modal
      id="multiple-currency-modal"
      :key="$i18n.locale"
      size="custom"
      hide-footer
      static
      centered
      @hide="searchText = ''"
    >
      <template #modal-title>{{ $t('multipleCurrency.modalTitle') }}</template>
      <p>{{ $t('multipleCurrency.modalDescription') }}</p>

      <t-input
        id="multiple-currency-modal-search-input"
        v-model="searchText"
        data-cy="multiple-currency-modal-search-input"
        :placeholder="$t('base.search')"
        class="mb-1"
        show-magnifier
      />

      <div class="content">
        <template v-if="!searchText">
          <h6>{{ $t('multipleCurrency.suggestedCurrencies') }}</h6>

          <div class="currency-list">
            <multiple-currency-option-button
              v-for="currency in suggestedCurrencyList"
              :key="`suggestion-${currency}`"
              :currency="currency"
              :data-cy="`suggestion-${currency}`"
              @click="selectCurrency(currency)"
            />
          </div>
        </template>

        <h6>{{ $t('multipleCurrency.allCurrencies') }}</h6>

        <div class="currency-list">
          <multiple-currency-option-button
            v-for="currency in filteredCurrencyList"
            :key="`filtered-${currency}`"
            :currency="currency"
            :is-selected="currency === selectedCurrency"
            :data-cy="`filtered-${currency}`"
            @click="selectCurrency(currency)"
          />
        </div>

        <p v-if="filteredCurrencyList.length === 0">
          <magnifier-icon class="icon-magnifier" />
          {{ $t('base.noResultAvailable') }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import { SUPPORT_MCP_CURRENCIES } from 'constants/currency'
import store from 'src/store'
import MultipleCurrencyOptionButton from './MultipleCurrencyOptionButton.vue'
import TInput from 'components/Common/TInput.vue'
import MagnifierIcon from 'assets/magnifier.svg?inline'
import { sendMcpTrackingEvent } from 'utilities/analytics'

export default defineComponent({
  name: 'MultipleCurrencyModal',
  components: { MultipleCurrencyOptionButton, TInput, MagnifierIcon },
  setup() {
    const { $t, $i18n } = getCurrentInstance().proxy

    const searchText = ref('')

    const selectedCurrency = computed(() => store.getters['mcp/selectedCurrency'])

    const allCurrenciesList = computed(() =>
      Object.entries(SUPPORT_MCP_CURRENCIES)
        .map(([currency, item]) => ({
          ...item,
          currency,
          translatedText: $t(`multipleCurrency.${currency}`, { locale: $i18n.locale })
        }))
        .sort((lhs, rhs) => lhs.translatedText.localeCompare(rhs.translatedText))
        .sort((item) => (selectedCurrency.value.includes(item.currency) ? -1 : 1))
    )

    const filteredCurrencyList = computed(() => {
      const normalizedLowerCaseSearch = searchText.value.normalize('NFKC').toLowerCase()

      return allCurrenciesList.value
        .filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === 'string' && value.toLowerCase().includes(normalizedLowerCaseSearch)
          )
        )
        .map((item) => item.currency)
    })

    const suggestedCurrencyList = computed(() =>
      allCurrenciesList.value
        .filter(
          (item) =>
            SUPPORT_MCP_CURRENCIES[item.currency].isSuggested &&
            item.currency !== selectedCurrency.value
        )
        .map((item) => item.currency)
    )

    function selectCurrency(currency) {
      sendMcpTrackingEvent({
        't-event_action': 'change-currency',
        't-currency_code': currency,
        't-old_currency_code': selectedCurrency.value
      })

      store.dispatch('mcp/setSelectedCurrency', currency)
      this.$bvModal.hide('multiple-currency-modal')
    }

    return {
      SUPPORT_MCP_CURRENCIES,
      filteredCurrencyList,
      suggestedCurrencyList,
      selectedCurrency,
      searchText,
      allCurrenciesList,
      selectCurrency
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~stylesheets/vars.scss';
@import '~stylesheets/mixins.scss';

.currency-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

::v-deep(#multiple-currency-modal) {
  overflow: hidden;
}

.content {
  height: 50vh;
  overflow: auto;
}

h6 {
  font-weight: bold;
  margin: 12px 0;
}

::v-deep(.modal .modal-custom) {
  max-width: 90%;
  width: 960px;
}
</style>
