import findFontColor from 'utilities/findFontColor'
import _get from 'lodash/get'
import _keyBy from 'lodash/keyBy'
import i18n from '../../i18n'
import {
  kidsModeList,
  ThirdPartyPayment,
  isOta,
  PaymentHubConfigs,
  PaymentHubIntegrationType
} from 'constants/tripla'
import { SUPPORT_MCP_CURRENCIES } from 'constants/currency'
import { addPrefixDataLayer } from 'utilities/dataLayer'
import { setTimezone } from 'utilities/date'
import { isMobile, isLine } from 'utilities/domOperation'

const DEFAULT_MAX_GUEST_PER_ROOM = 20
const DEFAULT_AVAILABLE_LANGUAGES = ['ja', 'en', 'zh_Hans', 'zh_Hant', 'ko']

const state = {
  code: null,
  baseURL: null,
  parentUrl: null,
  fromChatbot: null,
  isInChatbotIframe: false,
  chatbotAutoMessageAuthId: null,
  chatbotUserId: null,
  shouldLogoutAfterInitAPIArrived: false,
  appClosable: true,
  availableLanguages: null,
  init: {
    membership: null,
    data_layers: null
  },
  defaultSearch: {
    adults: 2,
    checkin_date_offset: 0
  },
  initialSearch: true,
  displayHeader: true,
  loginWidget: {
    display_pages: null,
    display_type: null,
    id: null,
    is_active: null,
    parent_element_selector: null,
    translations_attributes: null,
    privacy_url: null,
    term_of_use_url: null,
    allow_signup: false
  },
  hotelIdForMembershipModule: null,
  accessToken: null,
  experiment: null,
  hotelBrand: {},
  chatbotSetting: {
    show_on_booking_widget_platforms: []
  },
  hotelBrandSetting: null,
  paymentGatewaySetting: null,
  mcpPaymentGatewaySetting: null,
  metasearchSource: null,
  integrations: [],
  integrationMap: {},
  brandIntegrationMap: {},
  utmSource: {},
  facilitiesData: {},
  userSelectedPaymentMethod: null,
  otaSearchFacilitesData: [],
  bookLanguages: [],
  paymentHubIntegrationType: ''
}

const getters = {
  getSetting: ({ code, baseURL, parentUrl, parentPathname }) => ({
    code,
    baseURL,
    parentUrl,
    parentPathname
  }),
  getHotelCode: (state) => state.code,
  getUnifiedHotelCode: (state) => state.code?.toLowerCase().replace(/[\s-_]/g, ''),
  getHotelData: (state, getters) => state.facilitiesData?.[getters.getUnifiedHotelCode] || {},
  getHotelImages: (_state, getters) => getters.getHotelData.hotel_photos || [],
  getColor: (state) => (isOta ? '#c21e58' : state.init?.button_colour),
  getAvailableLanguages: (state) => state.availableLanguages,
  getFontColor: (state) => (isOta ? '#fff' : state.init?.font),
  getSpinnerVariant: (state) => (state.init && state.init.font === '#000' ? 'dark' : 'light'),
  getInit: (state) => state.init,
  getInitialSearch: (state) => state.initialSearch,
  getSiteController: (state) => state.init?.hotel_site_controller,
  getFirstPartyDomainSetting: (state) => state.hotelBrand?.first_party_cookie_setting,
  isFirstPartyDomainEnable: (state) =>
    state.hotelBrand?.first_party_cookie_setting?.status === 'completed',
  getEmailConsentSetting: (state) => state.hotelBrand?.email_consent_setting,
  getBrandID: (state) => state.init?.hotel_brand_id,
  getLogo: (state) => state.init && state.init.header === 'header_logo' && state.init.logo,
  isCustomHeader: (state) =>
    _get(state, 'init.header', null) === 'header_customize' &&
    _get(state, 'init.website_header_html', null),
  getFooter: (state) =>
    state.init && state.init.display_website_footer && state.init.website_footer_html,
  getFooterCSS: (state) => state.init && state.init.compiled_website_footer_css,
  isOpenInNewTab: (state) => state.init.booking_widget_type === 'standalone',
  isDisplayHeader: (state) => state.displayHeader,
  isChatbotActive: (state) => state.init?.hotel_active,
  isCouponActive: (state) => state.init?.coupon_active,
  isVoucherCouponActive: (state) => state.init?.voucher_coupon_active,
  isBrandFacility: (state) => state.init?.is_brand_facility,
  isMultiRoomSearchEnabled: (state) => state.init?.multi_rooms_search_enabled || false,
  showPricesTaxIncluded: (state) => state.init?.show_prices_tax_included ?? true,
  getHotelId: (state) => state.init?.hotel_id,
  getHotelLocale: (state) =>
    ['ko', 'zh_Hant'].includes(state.init?.hotel?.locale || 'ja')
      ? state.init?.hotel?.locale
      : 'ja',
  isFromChatbot: (state) => !!state.fromChatbot,
  isInChatbotIframe: (state) => state.isInChatbotIframe,
  isChatbotEnabledOnBW(state) {
    if (isLine()) {
      return false
    }
    const devicesHaveCB = state.chatbotSetting?.show_on_booking_widget_platforms || []

    return isMobile() ? devicesHaveCB.includes('mobile') : devicesHaveCB.includes('desktop')
  },
  isTurnstileEnabled: (state) => !!state.init?.turnstile_verification_enabled,
  isFacilitySignInEnabled: (state) => !!state.init?.facility_sign_in_enabled,
  isAppCloseable: (state) => state.appClosable,
  getChatbotAutoMessageAuthId: (state) => state.chatbotAutoMessageAuthId,
  getChatbotUserId: (state) => state.chatbotUserId,
  getShouldLogoutAfterInitAPIArrived: (state) => state.shouldLogoutAfterInitAPIArrived,
  getSearchType: (state) => {
    const defaultSearchType = _get(state, 'init.search_type')
    if (defaultSearchType === 'hotel_plan' && state.init?.plan_search_enabled) {
      return 'plan'
    } else {
      return 'rooms'
    }
  },
  getMembershipSettings: (state) => state.init && state.init.membership,
  getSessionToken: (state) => state.accessToken,
  getLoginWidget: (state) => state.loginWidget,
  isSignUpActive: (state) => state.loginWidget?.allow_signup,
  isTiersMode: (state) => {
    return kidsModeList.includes(state.init?.kids_setting?.kids_type)
  },
  isFromMetaSearch: (state) => {
    return state.metasearchSource != null
  },
  getHotelIdForMembershipModule: (state) => state.hotelIdForMembershipModule,
  getExperiment: (state) => state.experiment,
  getSearchFilters: (state) => {
    const searchFilters = _get(state.init, 'room_search_filters', []) || []
    return searchFilters.map((f) => ({
      id: String(f.id), // Make sure that id is String to future comparisons with other parts, for example, URL query string
      name: f.translations.find((t) => t.locale === i18n.locale)?.name ?? f.name
    }))
  },
  showDayUse: (state) => {
    return state.init?.day_use_search_active ?? false
  },
  getDefautSearch: (state) => ({ ...state.defaultSearch, order: 'price_low_to_high' }),
  getKidsSetting: (state) => {
    return _get(state.init, 'kids_setting')
  },
  showSharedBedKids: (state) => {
    return (
      _get(state.init, 'kids_setting.accept_type') !== 'not_accept' &&
      _get(state.init, 'kids_setting.kids_type') === 'share_bed' &&
      (_get(state.init, 'kids_setting.shared_bed_type') === 'per_adult' ||
        _get(state.init, 'kids_setting.shared_bed_type') === 'per_room')
    )
  },
  sharedBedKidDescription: (state) => {
    const locale = i18n.locale
    const translations = _get(state.init, 'shared_bed_kid_desc_translations', []) || []
    const translation = translations.find((x) => x.locale === locale)
    return _get(translation, 'shared_bed_kid_desc')
  },
  isPointsHidden: (state) =>
    state.hotelBrand?.point_program?.display_points_in_booking_widget === false, // nullable, so must be exactly false here
  kanjiNameForGuests: (state) => _get(state.init, 'kanji_name_required', false),
  canBookPreviousDay: (state) => state.init?.default_search_setting?.yesterday_bookable ?? false,
  bookAvailableRoomsOnlyDefaultSetting: (state) =>
    state.init?.default_search_setting?.available_rooms_only ?? false,
  nearbyHotelsSuggestionEnabled: (state) =>
    state.hotelBrandSetting?.nearby_hotels_suggestion_enabled ?? false,
  digicoEnabled: (state) => !!state.hotelBrandSetting?.digico_partner_code,
  roomRecommenderEnabled: (state) => state.hotelBrandSetting?.room_recommender_enabled,
  isCorporateMembershipLoginEnable: (state) =>
    state.loginWidget?.organization_login_widget_enabled ?? false,
  getPaymentGatewaySetting: (state, _, __, rootGetters) => {
    const paymentGatewaySetting = rootGetters['mcp/isMcpMode']
      ? state.mcpPaymentGatewaySetting
      : state.paymentGatewaySetting

    return {
      ...paymentGatewaySetting,
      isGMO: paymentGatewaySetting?.name === ThirdPartyPayment.GMO,
      isTapPayPayment: paymentGatewaySetting?.name === ThirdPartyPayment.TapPay,
      isGMOPaypal: paymentGatewaySetting?.name === ThirdPartyPayment.GMOPayPal,
      isPaygentPaypal: paymentGatewaySetting?.name === ThirdPartyPayment.PaygentPayPal,
      isPaygent: paymentGatewaySetting?.name === ThirdPartyPayment.Paygent,
      isAdyenGMO: paymentGatewaySetting?.name === ThirdPartyPayment.AdyenGMO,
      isAdyenGMOPaypal: paymentGatewaySetting?.name === ThirdPartyPayment.AdyenGMOPayPal
    }
  },
  getPaymentHubSetting: (state) => ({
    value: state.hotelBrandSetting?.payment_hub_config,
    isNone:
      !state.hotelBrandSetting?.payment_hub_config ||
      state.hotelBrandSetting?.payment_hub_config === PaymentHubConfigs.none,
    isDefault: state.hotelBrandSetting?.payment_hub_config === PaymentHubConfigs.default,
    isSurehigh: state.hotelBrandSetting?.payment_hub_config === PaymentHubConfigs.surehigh,
    isCustom: state.hotelBrandSetting?.payment_hub_config === PaymentHubConfigs.custom
  }),
  isPaymentHubKRW: (_state, getters) => {
    return getters.isKRW && !getters.getPaymentHubSetting.isNone
  },
  isHostedPaymentHub: (state, getters) => {
    return state.paymentHubIntegrationType === PaymentHubIntegrationType.Hosted
  },
  getEnableBwToOtaProcess: (state, getters) => {
    const isAllowedPaymentGateways = [ThirdPartyPayment.GMO, ThirdPartyPayment.AdyenGMO].includes(
      state.paymentGatewaySetting?.name
    )

    const isPaymentHubDefault =
      !state.hotelBrandSetting?.payment_hub_config ||
      state.hotelBrandSetting.payment_hub_config === PaymentHubConfigs.default
    const brandId = state.init?.hotel_brand_id
    const enableBwToOtaProcessBrand =
      process.env.ENABLE_BW_TO_OTA_PROCESS_BRAND_IDS === 'all' ||
      (process.env.ENABLE_BW_TO_OTA_PROCESS_BRAND_IDS?.split(',') || []).includes(String(brandId))

    return (
      enableBwToOtaProcessBrand && isAllowedPaymentGateways && isPaymentHubDefault && getters.isJPY
    )
  },
  getMetasearchSource: (state) => state.metasearchSource,
  getGtmDataLayer: (state) => addPrefixDataLayer(state.init.data_layers || {}),
  getAnalyticsSetting: (state) => state.init.analytics_setting_attributes,
  getHotelName: (state) => {
    const hotelTranslation = (state.init?.hotel?.translations_attributes || []).find(
      (translation) => translation.locale === i18n.locale
    )
    return hotelTranslation ? hotelTranslation.name : state.init.hotel_name
  },
  getHotelBrandName: (state) => state.init.hotel_brand_name,
  getPointName: (state) => {
    const pointTranslation = state.hotelBrand?.point_program?.translations.find(
      (translation) => translation.locale === i18n.locale
    )
    return pointTranslation?.point_naming && i18n.locale === 'en'
      ? `${pointTranslation?.point_naming}s`
      : pointTranslation?.point_naming || i18n.t('myAccount.point')
  },
  getCurrency: (state) => {
    return {
      code: state.init?.currency || 'JPY',
      symbol: SUPPORT_MCP_CURRENCIES[state.init?.currency]?.symbol || '¥'
    }
  },
  getBWOrigin: (state) => {
    return state.init?.standalone_settings?.url || process.env.BW_ORIGIN
  },
  getMeta: (state) => state.init?.standalone_settings || {},
  isApplyCouponPerPax: (state) => state.init?.percentage_coupon_discount_only ?? false,
  isTWD: (state) => state.init?.currency === 'TWD',
  isKRW: (state) => state.init?.currency === 'KRW',
  isJPY: (state) => state.init?.currency === 'JPY',
  isSupportCompanion: (state, getters) =>
    getters.isTiersMode && (state.init?.kids_tiers ?? []).some((tier) => tier.code === 'A'),
  getCreansmaerd: () => {
    const creansmaerdIntegration = state.brandIntegrationMap.creansmaerd
    if (creansmaerdIntegration?.active) {
      let login_host = creansmaerdIntegration.settings.login_host
      if (login_host && login_host.slice(-1) === '/') {
        login_host = login_host.slice(0, -1)
      }
      return {
        ...creansmaerdIntegration,
        settings: {
          ...creansmaerdIntegration.settings,
          login_host
        }
      }
    }
    return {}
  },
  getWbfSetting: (state) => {
    return state.integrationMap.wbf
  },
  getBrandIntegrations: (state) => {
    return state.brandIntegrationMap
  },
  getCustomSinupUrl: (state) => {
    const customRedirect = state.brandIntegrationMap.membership_customer_sign_up_redirect
    if (customRedirect?.active && customRedirect.settings?.url) {
      return customRedirect.settings.url
    }
  },
  getCustomLoginUrl: (state) => {
    const customRedirect = state.brandIntegrationMap.membership_customer_login_redirect
    if (customRedirect?.active && customRedirect.settings?.url) {
      return customRedirect.settings.url
    }
  },
  getCustomMyPageUrl: (state) => {
    const customRedirect = state.brandIntegrationMap.membership_menu_redirect
    if (customRedirect?.active && customRedirect.settings?.url) {
      return customRedirect.settings.url
    }
  },
  getUtmSource: (state) => state.utmSource,
  getUserSelectedPaymentMethod: (state) => state.userSelectedPaymentMethod,
  getOtaSearchFacilitesData: (state) => state.otaSearchFacilitesData,
  getBookLanguages: (state) => state.bookLanguages,
  isRegistrationPointEnabled: (state) =>
    state.hotelBrand?.point_program?.registration_point_enabled,
  getRegistrationPoint: (state) => state.hotelBrand?.point_program?.registration_point,
  getPointProgramDisabled: (state) => state.init?.point_program_disabled
}

const mutations = {
  setSetting: (state, { code, baseURL, parentUrl, parentPathname }) => {
    if (code) state.code = code
    if (baseURL) state.baseURL = baseURL
    if (parentUrl) {
      state.parentUrl = parentUrl
      localStorage.setItem('parentUrl', parentUrl)
    }
    if (parentPathname) state.parentPathname = parentPathname
  },
  setInitialSearch: (state, value) => {
    state.initialSearch = value
  },
  setFacilitiesData: (state, facilitiesData) => {
    state.facilitiesData = { ...(state.facilitiesData || {}), ...facilitiesData }
  },
  setFromChatbot: (state, isFromChatbot) => {
    state.fromChatbot = isFromChatbot
  },
  setIsInChatbotIframe: (state, value) => {
    state.isInChatbotIframe = value
  },
  setChatbotAutoMessageAuthId: (state, id) => {
    state.chatbotAutoMessageAuthId = id
  },
  setChatbotUserId: (state, id) => {
    state.chatbotUserId = id
  },
  setShouldLogoutAfterInitAPIArrived: (state, value) => {
    state.shouldLogoutAfterInitAPIArrived = value
  },
  setAppCloseable: (state, value) => {
    state.appClosable = value
  },
  setMetasearchSource: (state, metasearchSource) => {
    state.metasearchSource = metasearchSource
  },
  setInit: (
    state,
    {
      bookingWidgetSetting,
      chatbotSetting,
      hotelBrand,
      hotelBrandSetting,
      paymentGatewaySetting,
      mcpPaymentGatewaySetting,
      integrations,
      availableLanguages = DEFAULT_AVAILABLE_LANGUAGES,
      bookLanguages = [],
      paymentHubIntegrationType
    }
  ) => {
    state.init = bookingWidgetSetting

    if (!state.init.max_guests_per_room) {
      state.init.max_guests_per_room = DEFAULT_MAX_GUEST_PER_ROOM
    }
    try {
      if (state.init.button_colour && !isOta) {
        document.documentElement.style.setProperty('--theme-btn-color', state.init.button_colour)
        document.documentElement.style.setProperty(
          '--theme-font-color',
          findFontColor(bookingWidgetSetting.button_colour)
        )
      }
      if (state.init.header_colour && !isOta) {
        document.documentElement.style.setProperty(
          '--theme-header-background',
          state.init.header_colour
        )
        document.documentElement.style.setProperty(
          '--theme-header-color',
          findFontColor(state.init.header_colour)
        )
      }
    } catch (_e) {
      // pass
    }
    setTimezone(bookingWidgetSetting?.hotel?.time_zone)
    state.hotelBrand = hotelBrand
    state.hotelBrandSetting = hotelBrandSetting
    state.paymentGatewaySetting = paymentGatewaySetting
    state.mcpPaymentGatewaySetting = mcpPaymentGatewaySetting
    state.chatbotSetting = chatbotSetting
    state.integrations = integrations || []
    state.integrationMap = _keyBy(integrations, 'hotel_integration_type')
    state.brandIntegrationMap = _keyBy(hotelBrand?.integrations, 'integration_type')
    state.availableLanguages = availableLanguages.filter((locale) =>
      DEFAULT_AVAILABLE_LANGUAGES.includes(locale)
    )
    state.init.font =
      (bookingWidgetSetting.button_colour && findFontColor(bookingWidgetSetting.button_colour)) ||
      '#000'
    state.bookLanguages = bookLanguages
    state.paymentHubIntegrationType = paymentHubIntegrationType
  },
  setDisplayHeader: (state, flag) => {
    state.displayHeader = flag
  },
  setLoginWidgetData: (state, payload) => {
    state.loginWidget = payload
  },
  setSessionToken: (state, payload) => {
    state.accessToken = payload
  },
  setHotelIdForMembershipModule: (state, payload) => {
    state.hotelIdForMembershipModule = payload
  },
  setExperiment: (state, payload) => {
    state.experiment = payload
  },
  setDefaultSearch: (state, payload) => {
    if (payload) state.defaultSearch = payload
  },
  setUtmSource: (state, payload) => {
    state.utmSource = Object.entries(payload)
      .filter(([_, v]) => v)
      .reduce((a, [k, v]) => {
        return {
          ...a,
          [k]: v
        }
      }, {})
    // remove key if value is empty
  },
  setUserSelectedPaymentMethod: (state, payload) => {
    state.userSelectedPaymentMethod = payload
  },
  setOtaSearchFacilitiesData: (state, payload) => {
    state.otaSearchFacilitesData = [...(state.otaSearchFacilitesData || []), ...payload]
  }
}

const actions = {
  setSetting: ({ commit }, payload) => {
    commit('setSetting', payload)
  },
  setInitialSearch: ({ commit }, value) => {
    commit('setInitialSearch', value)
  },
  setFacilitiesData: ({ commit }, payload) => {
    commit('setFacilitiesData', payload)
  },
  setFromChatbot: ({ commit }, isFromChatbot) => {
    commit('setFromChatbot', isFromChatbot)
  },
  setIsInChatbotIframe: ({ commit }, value) => {
    commit('setIsInChatbotIframe', value)
  },
  setChatbotAutoMessageAuthId: ({ commit }, id) => {
    commit('setChatbotAutoMessageAuthId', id)
  },
  setChatbotUserId: ({ commit }, id) => {
    commit('setChatbotUserId', id)
  },
  setShouldLogoutAfterInitAPIArrived: ({ commit }, value) => {
    commit('setShouldLogoutAfterInitAPIArrived', value)
  },
  setAppCloseable: ({ commit }, value) => {
    commit('setAppCloseable', value)
  },
  setInit: ({ commit }, payload) => {
    commit('setInit', payload || {})
  },
  setHotelIdForMembershipModule: ({ commit }, payload) => {
    commit('setHotelIdForMembershipModule', payload)
  },
  setExperiment: ({ commit }, payload) => {
    commit('setExperiment', payload)
  },
  setDefaultSearch: ({ commit }, payload) => {
    commit('setDefaultSearch', payload)
  },
  setMetasearchSource: ({ commit }, payload) => {
    commit('setMetasearchSource', payload)
  },
  setUtmSource: ({ commit }, payload) => {
    commit('setUtmSource', payload)
  },
  setUserSelectedPaymentMethod: ({ commit }, payload) => {
    commit('setUserSelectedPaymentMethod', payload)
  },
  setOtaSearchFacilitiesData: ({ commit }, payload) => {
    commit('setOtaSearchFacilitiesData', payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
