import CancellationPolicies from 'api/CancellationPolicies'
import i18n from 'src/i18n'
import { generateHotelLabelFilters } from 'utilities/additionalQuestions'
import Reservations from 'api/Reservations'
import { NOTIFICATION } from 'constants/additionalQuestion'
import { isOta } from 'constants/tripla'

const getPlanLabel = (target) =>
  `${target.room_plan_code}_${
    target.matched_search_criteria?.adults_with_companion ||
    target.matched_search_criteria?.adults_count
  }_${
    target.matched_search_criteria?.children_with_kid_tier_A_companion ||
    target.matched_search_criteria?.children_count
  }`

export const actions = {
  addRoomInSearchForm: ({ commit }, room) => {
    commit('addRoomInSearchForm', room)
  },
  removeRoomInSearchForm: ({ commit }, roomIndex) => {
    commit('removeRoomInSearchForm', roomIndex)
  },
  setAttrInSearchForm: ({ commit }, payload) => {
    commit('setAttrInSearchForm', payload)
  },
  setSearchForm: ({ commit }, payload) => {
    commit('setSearchForm', payload)
  },
  setChatID: ({ commit }, chatID) => {
    commit('setChatID', chatID)
  },
  setRoomsResult: ({ commit }, roomData) => {
    if (!roomData) return
    roomData.rooms = roomData.rooms.map((room) => ({
      ...room,
      room_plan: room.room_plan.map((plan) => ({
        ...plan,
        planLabel: getPlanLabel(plan)
      }))
    }))
    commit('setRooms', roomData.rooms)
    commit('setExtraInventories', roomData.extra_inventories)
  },
  setPlansResult: ({ commit }, planData) => {
    if (!planData) return
    planData.plans = planData.plans.map((plan) => ({
      ...plan,
      rooms: plan.rooms.map((room) => ({
        ...room,
        booking_start_date: plan.booking_start_date,
        planLabel: getPlanLabel(room)
      }))
    }))
    commit('setPlans', planData.plans)
    commit('setExtraInventories', planData.extra_inventories)
  },
  setPreloadRoomsResult: ({ commit }, roomData) => {
    if (!roomData) return
    roomData.rooms = roomData.rooms.map((room) => ({
      ...room,
      room_plan: room.room_plan.map((plan) => ({
        ...plan,
        planLabel: getPlanLabel(plan)
      }))
    }))
    commit('setPreloadRooms', roomData.rooms)
  },
  setPreloadPlansResult: ({ commit }, planData) => {
    if (!planData) return
    planData.plans = planData.plans.map((plan) => ({
      ...plan,
      rooms: plan.rooms.map((room) => ({
        ...room,
        booking_start_date: plan.booking_start_date,
        planLabel: getPlanLabel(room)
      }))
    }))
    commit('setPreloadPlans', planData.plans)
  },
  setRoomSelectedCount: ({ commit }, payload) => {
    commit('setRoomSelectedCount', payload)
  },
  clearPreload: ({ commit }) => {
    commit('clearPreload')
  },
  decreaseRoomSelectedCount: (
    { commit, getters },
    { roomCode, planCode, adults, children, type }
  ) => {
    commit('decreaseRoomSelectedCount', { roomCode, planCode, adults, children, type })
  },
  setGalleryPhotos: ({ commit }, payload) => {
    commit('setGalleryPhotos', payload)
  },
  setGalleryIndex: ({ commit }, index) => {
    commit('setGalleryIndex', index)
  },
  loadCancellationPolicyContent: ({ commit }, { policyId, hotelId, title }) => {
    commit('setCancellationPolicyContent', null)
    if (!policyId) {
      commit('setCancellationPolicyContent', [[i18n.t('base.noData')]])
      return
    }

    CancellationPolicies.get(policyId, hotelId)
      .then((res) => [[title, ...res?.lines].filter(Boolean)])
      .then((response) => {
        commit('setCancellationPolicyContent', response)
      })
      .catch((err) => {
        commit('setCancellationPolicyContent', [[err.title || err.error]])
      })
  },
  setInitAPIArrived: ({ commit }, payload) => {
    commit('setInitAPIArrived', payload)
  },
  setPlanDetails: ({ commit }, plan) => {
    commit('setPlanDetails', plan)
  },
  setRoomDetails: ({ commit }, roomDetails) => {
    commit('setRoomDetails', roomDetails)
  },
  setTotalPriceFromSearch: ({ commit }, price) => {
    commit('setTotalPriceFromSearch', price)
  },
  removeSearchFormFilter: ({ commit }, filter) => {
    commit('removeSearchFormFilter', filter)
  },
  setCurrentSearchParams: ({ commit }, data) => {
    commit('setCurrentSearchParams', data)
  },
  clearSelectedRooms: ({ commit }) => {
    commit('clearSelectedRooms')
  },
  setCurrentSearchParamsHotelPlans: ({ commit }, data) => {
    commit('setCurrentSearchParamsHotelPlans', data)
  },
  setCurrentSearchParamsRoomTypes: ({ commit }, data) => {
    commit('setCurrentSearchParamsRoomTypes', data)
  },
  fetchNotifications: async ({ dispatch, rootGetters, commit }, { pageLabel, roomPlanCodes }) => {
    try {
      const labelFilters = generateHotelLabelFilters(pageLabel, rootGetters)
      const res = await Reservations.getAdditionalQuestionList({
        q: JSON.stringify({ label_filters: labelFilters }),
        room_plan_codes: roomPlanCodes,
        [isOta ? 'tripla_ota_enabled' : 'tripla_book_enabled']: true
      })
      commit('setFacilityAdditionalQuestionsSuccess', true)
      const notifications = res.data.filter((item) => item.question_type === NOTIFICATION)
      dispatch('setNotificationMap', notifications)
      commit('booking/setNotifications', notifications, { root: true })
    } catch (err) {
      commit('setFacilityAdditionalQuestionsSuccess', false)
      dispatch('error/setSearchResultError', err.title, { root: true })
    }
  },
  fetchNotificationsOnConfirmation: ({ commit }, route) => {
    const { params, query } = route
    return Reservations.getAdditionalQuestions(
      {
        reservationID: params.confirmationNumber,
        hotelId: query.code
      },
      {
        bypass_token: query.bypass_token,
        q: JSON.stringify({
          label_filters: [
            { labels: ['place_confirmation_screen'] },
            { labels: ['place_booking_process'] }
          ]
        })
      }
    ).then((res) => {
      const notifications = res.data?.additional_questions.filter(
        (item) => item.question_type === NOTIFICATION && item.ownable_type !== 'HotelBrand'
      )
      commit('booking/setNotifications', notifications, { root: true })
    })
  },
  setNotificationMap: ({ commit }, notifications) => {
    const notificationMap = {}
    notifications.forEach((notification) => {
      for (let i = 0; i < notification.hotel_plan_codes.length; i++) {
        const hotelPlanCode = notification.hotel_plan_codes[i]
        if (!notificationMap[hotelPlanCode]) {
          notificationMap[hotelPlanCode] = []
        }
        notificationMap[hotelPlanCode].push(notification)
      }
    })
    commit('setNotificationMap', notificationMap)
  },
  setRoomRateMap: ({ commit }, roomRateMap) => {
    commit('setRoomRateMap', roomRateMap)
  },
  setPromotionMap: ({ commit }, promotionMap) => {
    commit('setPromotionMap', promotionMap)
  },
  setWbfPriceMap: ({ commit }, payload) => {
    commit('setWbfPriceMap', payload)
  },
  setRoomSearchSuccess: ({ commit }, payload) => {
    commit('setRoomSearchSuccess', payload)
  }
}
