import router from 'routes'
import _pick from 'lodash/pick'

const state = {
  initCurrency: null
}

const getters = {
  initCurrency: (state) => state.initCurrency
}

const mutations = {
  setInitCurrency(state, value) {
    state.initCurrency = value
  }
}

const actions = {
  setInitCurrency({ commit }, currency) {
    commit('setInitCurrency', currency)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
