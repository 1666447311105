import _get from 'lodash/get'
import _min from 'lodash/min'
import _map from 'lodash/map'
import _flatten from 'lodash/flatten'
import _mapValues from 'lodash/mapValues'
import _flatMap from 'lodash/flatMap'
import _groupBy from 'lodash/groupBy'
import _cloneDeep from 'lodash/cloneDeep'
import { selectAdultChildCount } from 'utilities/selectedBar'
import { FilterTypes } from 'constants/tripla'
import { filterNotificationsByCheckinDate } from 'utilities/notifications'
import { dateOfToday } from 'utilities/date'

export const getters = {
  getSearchForm: (state) => state.searchForm,
  isVoucherMode: (state) => state.searchForm.mode === 'voucher',
  isUndated: (state) => state.searchForm.search_type === 'undated',
  getChatID: (state) => state.chatID,
  getFormattedAges: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['setting/isTiersMode']) return []
    if (!state.searchForm.kidsTiers) return []
    const shortenKidsTiers = state.searchForm.kidsTiers
      .map((tier) => {
        return {
          code: tier.code,
          count: tier.children
        }
      })
      .filter((tier) => {
        return tier.count !== 0
      })
    return shortenKidsTiers
  },
  getNights: (state) => {
    if (state.searchForm.search_type === 'undated') {
      return state.searchForm.nights || 0
    }
    if (state.searchForm.checkout === state.searchForm.checkin || !state.searchForm.checkout)
      return 0

    // YYYY/MM/DD -> YYYY-MM-DD to avoid timezone and daylight saving time
    const checkout = state.searchForm.checkout?.replace(/\//gi, '-')
    const checkin = state.searchForm.checkin?.replace(/\//gi, '-')
    return Math.round((new Date(checkout) - new Date(checkin)) / 86400000)
  },
  getRooms: (state, getters, rootState, rootGetters) => {
    const rooms = _cloneDeep(state.rooms || state.preloadRooms)
    // eslint-disable-next-line no-unused-expressions
    rooms?.forEach((room) => {
      // eslint-disable-next-line no-unused-expressions
      room?.room_plan?.forEach((plan) => {
        plan.inclusiveExtraLimit = _min([
          ..._map(plan?.inclusive_extra_ids, (id) => state.extraInventories[id]?.inventory),
          Number.MAX_SAFE_INTEGER
        ])
      })
    })

    if (
      rootGetters['setting/isTiersMode'] &&
      !state.searchForm.is_including_occupied &&
      !getters.isUndated &&
      state.searchForm.mode !== 'voucher'
    ) {
      return (
        rooms?.filter((room) => {
          room.room_plan = room.room_plan.filter((plan) => {
            const saleLimit = plan.sale_limit
              ? Math.min(plan.sale_limit, plan.plan_stock_count)
              : Number.MAX_SAFE_INTEGER
            return state.searchForm.room_count <= saleLimit
          })
          return state.searchForm.room_count <= room.room_count && room.room_plan.length > 0
        }) || null
      )
    }
    return rooms
  },
  getIsMultiple: (state) => !!state.searchForm.rooms && state.searchForm.rooms.length > 1,
  getGalleryPhotos: (state) => state.galleryPhotos,
  getGalleryIndex: (state) => state.galleryIndex,
  getPlans: (state, getters, rootState, rootGetters) => {
    const plans =
      (state.plans || state.preloadPlans)?.map((plan) => ({
        ...plan,
        inclusiveExtraLimit: _min([
          ..._map(plan?.inclusive_extra_ids, (id) => state.extraInventories[id]?.inventory),
          Number.MAX_SAFE_INTEGER
        ])
      })) || null

    if (
      rootGetters['setting/isTiersMode'] &&
      !state.searchForm.is_including_occupied &&
      !getters.isUndated &&
      state.searchForm.mode !== 'voucher'
    ) {
      return (
        plans
          ?.map((plan) => ({
            ...plan,
            rooms: plan.rooms.filter((room) => {
              const saleLimit = plan.sale_limit
                ? Math.min(plan.sale_limit, room.plan_stock_count)
                : Number.MAX_SAFE_INTEGER
              return (
                state.searchForm.room_count <= room.room_count &&
                state.searchForm.room_count <= saleLimit
              )
            })
          }))
          .filter((plan) => plan.rooms.length > 0) || null
      )
    }
    return plans
  },
  hasPreloadData: (state) => state.preloadPlans || state.preloadRooms,
  getCancellationPolicyModal: (state) => state.cancellationPolicy.showModal,
  getCancellationPolicyContent: (state) => state.cancellationPolicy.content,
  getRoomsFromPlans: (state) =>
    _flatten(
      state.plans.map((plan) =>
        plan.rooms.map((room) => ({ ...room, inclusive_extra_ids: plan.inclusive_extra_ids }))
      )
    ),
  getSelectedRooms: (state, getters) => {
    const rooms = []
    for (const eachRoom of state.selectedRooms) {
      const {
        roomTypeCode,
        roomPlanCode,
        quantity,
        type,
        numberOfAdults,
        numberOfChildren,
        salePrice,
        tax,
        scrapeIds,
        promotionID,
        couponCode
      } = eachRoom
      let selectedRoom = null
      let selectedPlan = null
      let planName = null
      let roomName = null
      let hotelPlanCode = null
      let inclusiveExtraIds = []
      let guestCount = {}

      if (type === 'rooms') {
        selectedRoom = state.rooms.find((room) => {
          const foundPlan = room.room_plan.find((plan) => {
            return (
              plan.room_plan_code === roomPlanCode &&
              (plan.matched_search_criteria?.adults_with_companion ||
                plan.matched_search_criteria?.adults_count) === numberOfAdults &&
              (plan.matched_search_criteria?.children_with_kid_tier_A_companion ||
                plan.matched_search_criteria?.children_count) === numberOfChildren
            )
          })
          if (foundPlan) {
            selectedPlan = foundPlan
          }
          return !!foundPlan
        })
        guestCount = selectAdultChildCount(selectedPlan)
        hotelPlanCode = selectedPlan?.hotel_plan_code
        planName = _get(selectedPlan, 'room_plan_name')
        roomName = _get(selectedRoom, 'room_type_name')
        inclusiveExtraIds = _get(selectedPlan, 'inclusive_extra_ids')
      } else if (type === 'plan') {
        const rooms = getters.getRoomsFromPlans || []
        selectedRoom = rooms.find(
          (room) =>
            room.room_type_code === roomTypeCode &&
            (room.matched_search_criteria?.adults_with_companion ||
              room.matched_search_criteria?.adults_count) === numberOfAdults &&
            (room.matched_search_criteria?.children_with_kid_tier_A_companion ||
              room.matched_search_criteria?.children_count) === numberOfChildren &&
            room.room_plan_code === roomPlanCode
        )
        guestCount = selectAdultChildCount(selectedRoom)
        hotelPlanCode = selectedRoom?.hotel_plan_code
        planName = _get(selectedRoom, 'room_plan_name')
        roomName = _get(selectedRoom, 'room_name')
        inclusiveExtraIds = _get(selectedRoom, 'inclusive_extra_ids')
      }
      for (let index = 0; index < quantity; index++) {
        rooms.push({
          roomName,
          plan: planName,
          inclusiveExtraIds,
          adults: guestCount.adultsCount,
          children: guestCount.childrenCount,
          roomCode: roomTypeCode,
          planCode: roomPlanCode,
          hotelPlanCode: hotelPlanCode,
          cheapestPriceInfo: {
            salePrice,
            tax,
            scrapeIds,
            promotionID,
            couponCode
          },
          type
        })
      }
    }

    return rooms
  },
  getSelectedRoomCount: (state) => ({
    roomTypeCode,
    roomPlanCode,
    numberOfAdults,
    numberOfChildren,
    type
  }) => {
    const selectedRoom = state.selectedRooms.find(
      (room) =>
        room.roomTypeCode === roomTypeCode &&
        room.roomPlanCode === roomPlanCode &&
        room.numberOfAdults === numberOfAdults &&
        room.numberOfChildren === numberOfChildren &&
        room.type === type
    )

    return _get(selectedRoom, 'quantity', 0) || 0
  },
  getRemainsExtraInventory: (state, getters) => {
    // logic for extra.inclusive_sales_type === 'per_room_per_night'
    const selectedCounts = _mapValues(
      _groupBy(_flatMap(getters.getSelectedRooms, 'inclusiveExtraIds')),
      (i) => i.length
    )
    return _mapValues(state.extraInventories, (extra) => {
      return (extra.inventory ?? Number.MAX_SAFE_INTEGER) - (selectedCounts[extra.extra_id] || 0)
    })
  },
  getSelectedRoomsByPlanCode: (state, getters) => {
    return _groupBy(getters.getSelectedRooms, 'hotelPlanCode')
  },
  getRoomsCount: (state, getters) => getters.getRooms?.length ?? null,
  getPlansCount: (state, getters) => getters.getPlans?.length ?? null,
  getTotalKidsCount: (state) => {
    const isKidsTiersMode = state.searchForm.kidsTiers && state.searchForm.kidsTiers.length > 0
    if (isKidsTiersMode) {
      return state.searchForm.kidsTiers.reduce((acc, item) => {
        return acc + item.children
      }, 0)
    }
    if (!state.searchForm.rooms || state.searchForm.rooms.length === 0) return 0
    return state.searchForm.rooms.reduce((acc, item) => {
      return acc + (item.children || 0)
    }, 0)
  },
  getTotalAdultsCount: (state) => {
    if (!state.searchForm.rooms || state.searchForm.rooms.length === 0) return 0
    return state.searchForm.rooms.reduce((acc, item) => {
      return acc + item.adults
    }, 0)
  },
  isInitAPIArrived: (state) => state.initAPIArrived,
  getPlanDetails: (state) => state.planDetails,
  getRoomDetails: (state) => state.roomDetails,
  getTotalPriceFromSearch: (state) => state.totalPriceFromSearch,
  getCurrentSearchParams: (state) => {
    if (state.currentSearchParamsForAPI) return state.currentSearchParamsForAPI

    // Since state.currentSearchParamsForAPI may be null after refresh, we can try to access it from the session storage
    try {
      return JSON.parse(sessionStorage.getItem('currentSearchParamsForAPI'))
    } catch (e) {
      return null
    }
  },
  getSearchFormFilters: (state) => {
    const filters = (state.searchForm?.filter || []).map((x) => {
      return { name: x, type: FilterTypes.Filter }
    })
    const hotelPlanCodes = (state.searchForm?.hotelPlanCodes || []).map((x) => {
      return { name: x, type: FilterTypes.HotelPlanCode }
    })
    const roomTypeCodes = (state.searchForm?.roomTypeCodes || []).map((x) => {
      return { name: x, type: FilterTypes.RoomTypeCode }
    })
    const VoucherCouponCode = state.searchForm?.voucher_coupon_code
      ? {
          name: state.searchForm?.voucher_coupon_code,
          type: FilterTypes.VoucherCouponCode
        }
      : null

    return [
      ...filters,
      ...hotelPlanCodes,
      ...roomTypeCodes,
      ...(VoucherCouponCode ? [VoucherCouponCode] : [])
    ]
  },
  getCurrentSearchParamsHotelPlans: (state) => state.currentSearchParamsHotelPlans || [],
  getCurrentSearchParamsRoomTypes: (state) => state.currentSearchParamsRoomTypes || [],
  getNotificationMap: (state, _, __, rootGetters) => {
    const result = {}
    const checkin = rootGetters['search/isUndated']
      ? dateOfToday()
      : rootGetters['search/getSearchForm'].checkin
    Object.entries(state.notificationMap).forEach(([key, value]) => {
      result[key] = filterNotificationsByCheckinDate(value, checkin)
    })
    return result
  },
  getRoomRateMap: (state) => state.roomRateMap,
  getPromotionMap: (state) => state.promotionMap,
  getWbfPriceMap: (state) => state.wbfPriceMap,
  getFacilityAdditionalQuestionsSuccess: (state) => state.facilityAdditionalQuestionsSuccess,
  getRoomSearchSuccess: (state) => state.roomSearchSuccess
}
