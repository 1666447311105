import store from 'store'
import { appendScript } from 'utilities/appendScript'
import { generateGtmPayload } from '@tripla/gtm-converter'
import { getTriplaUID } from 'utilities/triplaUID'
import { getGA4GlobalMeta } from 'src/helpers/ga4'
import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'

export const pageView = (context, params = {}) => {
  // custom dimension 9: booking_method should be booking_widget or chat_booking_widget
  // sent from the backend it will be chat (https://github.com/umami-dev/tripla/pull/4912)
  const isFromChatbot = store.getters['setting/isFromChatbot']
  const bookingMethod = isFromChatbot ? 'chat_booking_widget' : 'booking_widget'

  // used in GA ga('set', 'exp', '$experimentId.$variationId')
  // https://developers.google.com/optimize/devguides/experiments
  // passed from chatbot to BW in the url query
  const experiment = store.getters['setting/experiment']
  // no tracking ID was passed -> send to all registered accounts
  // using vue-analytics
  if (params.path) {
    if (experiment) {
      if (context.$ga) context.$ga.set('exp', experiment)
    }
    // this is for tripla GA setting customized pagepath (it doesn't actually trigger pageview)
    // eslint-disable-next-line no-unused-expressions
    window.dataLayer?.push({
      pagePath: params.path,
      title: context.$route.name,
      bookingMethod,
      hotel_id: store.getters['setting/getHotelId'],
      hotel_brand_id: store.getters['setting/getBrandID']
    })

    // this is for both hotel GA and tripla GA
    // if path is specified, pass that along with the title
    if (context.$ga)
      context.$ga.page({
        page: params.path,
        title: context.$route.name
      })
    return
  }

  // otherwise (path not specified), pass the info from router (path, location and title)
  if (context.$ga) context.$ga.page(context.$router)
}

export const bookEvent = (context, roomCount) => {
  const eventCategory = 'book'
  const eventAction = 'click'
  const eventLabel = roomCount > 1 ? 'Book rooms' : 'Book a room'

  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    event: 'book_rooms',
    eventRoomCount: roomCount,
    eventBookLabel: eventLabel
  })

  if (context.$ga)
    context.$ga.event({
      eventCategory,
      eventAction,
      eventLabel,
      eventValue: roomCount
    })
}

export const sendDataLayerEvent = (payload) => {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push(payload)
}

export const selectItemEvent = (response) => {
  const initialSetting = store.getters['setting/getInit']
  sendDataLayerEvent({
    event: 'select_item',
    currencyCode: initialSetting?.currency,
    ecommerce: {
      items: response.rooms.map((room, roomIndex) => {
        return {
          item_name: `${initialSetting?.hotel_id} - room type ${room.room_type_code}`,
          item_id: room.room_plan_code,
          price: room.total_price,
          item_brand: `${initialSetting?.hotel_id} - ${initialSetting?.hotel_name}`,
          item_category: `${response.adults} adult(s)`,
          item_category_2: `${response.nights} night(s)`,
          item_list_name: 'Selected List',
          index: roomIndex,
          quantity: 1
        }
      })
    },
    _clear: true
  })
}

export const searchPath = (route, hotelID) => {
  const path = `/reservation-tripla/${hotelID}/search?`
  const params = Object.entries(route.query)
    .filter((param) => param[0] !== 'code' && param[1] !== [] && param[1])
    .map((param) => `${param[0]}=${param[1]}`)
    .join('&')
  return path + params
}
export const guestDetailPath = (hotelID) => `/reservation-tripla/${hotelID}/guest-detail`
export const paymentPath = (hotelID) => `/reservation-tripla/${hotelID}/payment`
export const confirmationPath = (hotelID) => `/reservation-tripla/${hotelID}/confirmation`
export const memberOpenSignUpPath = (hotelID) =>
  `/member-registration-tripla/${hotelID}/open-signup`
export const memberDoneSignUpPath = (hotelID) => `/member-registration-tripla/${hotelID}/done`
export const memberSignInPath = (hotelID) => `/member-registration-tripla/${hotelID}/sign-in`
export const corporateSignInPath = (hotelID) => `/corporate/${hotelID}/sign-in`

function sendTriplaEvent(data) {
  if (!data['t-event_type']) {
    data['t-event_type'] = 'customer'
  }
  if (data.event && data.event !== 'triplaEvent') {
    // eslint-disable-next-line no-unused-expressions
    window.dataLayer?.push({
      ...data
    })
  }
  if (data['t-page_category'] === 'Search Result') {
    data._clear = true
    data['t-transaction_id'] = null
    data['t-transaction_price_without_tax'] = null
    data['t-transaction_tax_amount'] = null
    data['t-transaction_total_price'] = null
  }
  if (!data['t-hotel_brand_id'] && store.getters['setting/getBrandID']) {
    data['t-hotel_brand_id'] = store.getters['setting/getBrandID']
  }
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    ...data,
    event: 'triplaEvent'
  })
}

export function sendMcpTrackingEvent(data) {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    ...data,
    't-hotel-id': store.getters['setting/getHotelId'],
    't-sent_at': new Date().toISOString(),
    event: 'triplaMcpEvent'
  })
}

function sendTriplaRoomEvent(room) {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    ...room,
    event: 'triplaRoomEvent',
    't-cookie_id': getTriplaUID()
  })
}

export function sendLoginEvent({ cookieID, userID }) {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    event: 'login',
    't-cookie_id': cookieID,
    't-user_id': userID
  })
}

export function sendSetUserIdEvent({ cookieID, userID }) {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    event: 'setUserId',
    't-cookie_id': cookieID,
    't-user_id': userID
  })
}

export function sendSignUpEvent() {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    event: 'sign_up',
    't-cookie_id': getTriplaUID()
  })
}

export function sendLogoutEvent() {
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    event: 'logout',
    't-cookie_id': getTriplaUID(),
    't-user_id': null,
    user_id: null
  })
}

export function sendRecommenderEvent({ checkin, checkout, recommender_id, query }) {
  const url = new URL('/booking/result', location.origin)
  Object.keys(query).forEach((key) => {
    if (!query[key]) {
      delete query[key]
    }
  })
  const searchParams = new URLSearchParams(query)
  url.search = searchParams.toString()
  // eslint-disable-next-line no-unused-expressions
  window.dataLayer?.push({
    event: 'recommend',
    't-cookie_id': getTriplaUID(),
    't-user_id': store.getters['membership/getCurrentUser']?.user?.id || '',
    't-checkin_date': checkin,
    't-checkout_date': checkout,
    't-recommender_id': recommender_id,
    't-click_URL': url.href
  })
}

export const sendGtmEvent = ({ type, data, gtmSettings }) => {
  const altedData = {
    ...data,
    meta: { ...data.meta, ...getGA4GlobalMeta() }
  }
  const dataLayer = generateGtmPayload({ type, data: altedData, gtmSettings })
  if (!window.dataLayer) {
    window.dataLayer = []
  }
  for (const data of dataLayer) {
    sendTriplaEvent(data)

    if (data['t-rooms']) {
      data['t-rooms'].forEach((room) => {
        sendTriplaRoomEvent(room)
      })
    }
  }
  return dataLayer
}

export function initGtm() {
  const settings = store.getters['setting/getAnalyticsSetting']
  if (settings?.head_tag) {
    appendScript('head', settings.head_tag)
  }

  if (settings?.body_tag) {
    appendScript('body', settings.body_tag)
  }
}

const GTM_SESSION_ATTRIBUTES = ['client_id', 'session_id']
window.dataLayer = window.dataLayer || []
const gtag = function () {
  window.dataLayer.push(arguments)
}

export const getClientAndSessionIds = async () => {
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  try {
    const measurements = document.documentElement.innerHTML.match(/G-[A-Z0-9]+/g)
    const ret = _mapValues(_keyBy(measurements), () => ({})) // [ a, b, c ] => { a:{}, b:{}, c:{} }

    const jobs = []
    GTM_SESSION_ATTRIBUTES.forEach((attribute) => {
      measurements.forEach((measurementId) => {
        jobs.push(
          new Promise((resolve) => {
            gtag('get', measurementId, attribute, (val) => {
              ret[measurementId][attribute] = val
              resolve()
            })
          })
        )
      })
    })
    return Promise.all(jobs).then(() => ret)
  } catch (_e) {
    // pass
  }
  return []
}

export const setClientAndSessionIds = (data) => {
  try {
    Object.entries(data).forEach(([measurementId, values]) => {
      GTM_SESSION_ATTRIBUTES.forEach((attribute) => {
        gtag('config', measurementId, attribute, values[attribute])
      })
    })
  } catch (_e) {
    // pass
  }
}
