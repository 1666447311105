import { compareDateAfter, compareDateBefore, isSameDate } from 'utilities/date'

export const filterNotificationsByCheckinDate = (notifications, checkin) => {
  if (!notifications || !checkin) return notifications || []

  return notifications.filter((notification) => {
    const { display_start_date, display_end_date } = notification
    let startDateMatched = true
    let endDateMatched = true
    if (display_start_date) {
      const startDate = display_start_date.replace(/-/g, '/')
      startDateMatched = compareDateAfter(checkin, startDate) || isSameDate(checkin, startDate)
    }

    if (display_end_date) {
      const endDate = display_end_date.replace(/-/g, '/')
      endDateMatched = compareDateBefore(checkin, endDate) || isSameDate(checkin, endDate)
    }

    return startDateMatched && endDateMatched
  })
}
